<template>
  <v-theme-provider>
    <base-section
      id="bannertwo"
      class="primary pb-3"
    >
      <div class="banner-text white--text banner pl-10">
        Tricks Freundschaft Respekt Tanz Tricks Freundschaft Respekt
      </div>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',
    provide: {
      heading: { align: 'center' },
    },
  }
</script>

<style>
.banner-text{
  font-weight: bold!important;
  word-spacing: 3.4rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.banner{
  width:100%;
  height:27px;
  white-space: nowrap;
  overflow-x: hidden!important;
  font-size: calc(16px + (26 - 14) * ((100vw - 100px) / (1600 - 100)))!important;

}
.bg{
  color:#F0DEDE!important;
}
</style>
